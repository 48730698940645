import type { NextPage } from 'next';
import Head from 'next/head';
import { Twitter, Telegram, Maildotru } from '@icons-pack/react-simple-icons';

const HomePage: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Unicorn Powered - Web3 Web and Contract Development</title>
        <meta
          name="description"
          content="Unicorn Powered NFT Web3 Development"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <header>
        <h1>
          Unicorn<span>Powered</span>
        </h1>
      </header>

      <main>
        <p>POWERING YOUR NEXT WEB3 PROJECT</p>
        <address>
          <a href="http://twitter.com/unipowerednfts" rel="nofollow noreferrer">
            <Twitter size={25} /> Twitter
          </a>
          <a href="https://t.me/unicornpowered" rel="nofollow noreferrer">
            <Telegram size={25} /> Telegram
          </a>
          <a href="mailto:caleb@unicornpowered.xyz" rel="nofollow noreferrer">
            <Maildotru size={25} /> Email
          </a>
        </address>
      </main>

      <footer>
        POWERED BY{' '}
        <span role="img" aria-label="unicorn">
          🦄
        </span>
      </footer>
    </div>
  );
};

export default HomePage;
